<template>
  <ListPage :gridData="data" :gridConfig="gridConfig" :filters="filters" />
</template>

<script>
import ListPage from "@/components/penal-retail-helpers/ListPage";
import { db } from "@/firebase/firebaseConfig";

import bandsFormConfig from "@/helpers/bandsFormConfig";

let collectionName = "bands";

export default {
  components: {
    ListPage,
  },
  data() {
    let attributes = bandsFormConfig;

    let columns = [];
    columns.push({
      headerName: this.$t(`${collectionName}.columns.alias`),
      filter: false,
      width: 100,
      field: "alias",
    });

    let filters = [];
    filters.push({
      size: "1/5",
      mobileSize: "1/2",
      extraClasses: "mb-5",
      search: true,
    });
    if (
      ["supercustomer", "customer"].includes(
        this.$store.state.AppActiveUser.role
      )
    ) {
      filters.push({
        size: "4/5",
        mobileSize: "1/2",
        extraClasses: "mb-5",
      });
    } else {
      filters.push({
        size: "4/5",
        mobileSize: "1/2",
        sidebar: true,
        i18n: `${collectionName}.buttons.new`,
        extraClasses: "text-right",
        sidebarConfig: {
          title: `${collectionName}.buttons.new`,
          collectionName: collectionName,
          attributes: attributes,
          prevalidate: async (data, collection, errors) => {
            let snapshot = await collection
              .where("alias", "==", data.alias)
              .get();

            if (snapshot.docs && snapshot.docs.length > 0) {
              errors.add({
                field: "alias",
                msg: this.$t(`Validations.${collectionName}.alias.unique`),
              });
              return true;
            }

            return false;
          },
        },
      });
    }

    return {
      data: [],
      filters,
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs: columns,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: this.onRowClicked,
      },
    };
  },
  created() {
    this.$bind(
      "data",
      db
        .collection(collectionName)
        .where("deleted", "==", false)
        .orderBy("alias"),
      { wait: true }
    );
  },
  methods: {
    onRowClicked(params) {
      this.$router
        .push(`/settings/${collectionName}/${params.data.id}`)
        .catch(() => {});
    },
  },
};
</script>
